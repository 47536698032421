// .rm-audio-player-provider , #rm-audio-player{
//     --rm-audio-player-interface-container:var(--spectrum-global-color-gray-100);
//     --rm-audio-player-volume-background: #bc4a4a;
//     --rm-audio-player-volume-panel-background:#f2f2f2;
//     --rm-audio-player-volume-panel-border:#ccc;
//     --rm-audio-player-volume-thumb: #d3d3d3;
//     --rm-audio-player-volume-fill:rgba(0, 0, 0, 0.5);
//     --rm-audio-player-volume-track:#ababab;
//     --rm-audio-player-track-current-time:#0072F5;
//     --rm-audio-player-track-duration:#8c8c8c;
//     --rm-audio-player-progress-bar:#0072F5;
//     --rm-audio-player-progress-bar-background:#D1D1D1;
//     --rm-audio-player-waveform-cursor:var(--spectrum-global-color-gray-800);
//     --rm-audio-player-waveform-background:var(--rm-audio-player-progress-bar-background);
//     --rm-audio-player-waveform-bar:var(--rm-audio-player-progress-bar);
//     --rm-audio-player-sortable-list:var(--spectrum-global-color-gray-200);
//     --rm-audio-player-sortable-list-button-active:#0072F5;
//     --rm-audio-player-selected-list-item-background:var(--spectrum-global-color-gray-500);
// }

.dogaArtistListContainer {
  display: flex;
}
.dogaArtistCardContainer {
  padding: 5px;
  margin: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dogaArtistCardContainer:hover {
  background-color: red;
}
.dogaArtistCardImage {
  width: 40px;
  height: 40px;
}
.dogaArtistCardName {
  font-size: 10px;
}
#currentArtistId {
  background-color: green;
}

.dogaArtistMainImage {
  width: 100px;
  height: 100px;
}

.dogaAlbumListContainer {
  display: flex;
}
.dogaAlbumCardContainer {
  padding: 5px;
  margin: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dogaAlbumCardContainer:hover {
  background-color: aqua;
}
.dogaAlbumCardCover {
  height: 50px;
  width: 50px;
}
.dogaAlbumCardTitle {
  height: 50px;
  width: 50px;
  font-size: 10px;
}

.dogaAlbumCover {
  height: 300px;
  width: 300px;
}

.dogaTrackContainer {
  display: inline-block;
  cursor: pointer;
}
.dogaTrack {
  display: flex;
  align-items: center;
}
.dogaTrack:hover {
  color: pink;
  background-color: aquamarine;
}
.dogaTrackPlayIcon {
  margin-right: 5px;
}
.dogaTrackPosition {
  margin-right: 10px;
  color: white;
  em {
    color: red;
  }
}
.dogaTrackName {
  letter-spacing: 5px;
  margin-right: 7px;
}
.dogaTrackDuration {
  letter-spacing: 3px;
  color: red;
}

// hover

.dogaTracklistContainer {
}
