.discogGpt {
  margin-top: 7px;
  margin-bottom: 100px;
}

.gptButton {
  font-size: 10px;
  border: none;
  background-color: rgb(87, 88, 85);
  color: rgb(166, 235, 19);
  font-family: "Russo One";
  letter-spacing: 4px;
  margin-right: 5px;
}
.gptButton:hover {
  background-color: rgb(183, 169, 169);
  color: rgb(166, 235, 19);
  transition: 1s;
}

.podcastButton {
  font-size: 10px;
  border: none;
  background-color: rgb(132, 179, 38);
  color: rgb(8, 46, 84);
  font-family: "Russo One";
  letter-spacing: 4px;
  margin-right: 5px;
}
.podcastButton:hover {
  background-color: rgb(119, 2, 2);
  color: rgb(166, 235, 19);
  transition: 1s;
}
.gptResponse {
  margin-top: 20px;
  font-size: 12px;
  background-color: white;
  width: 58%;
  padding: 10px;
}

/* loading bar */
.loadContainer {
  width: 180px;
  align-items: center;
  text-align: center;
}
.waveContainer {
  height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: rgb(116, 239, 2);
  animation: loadcolors 60s linear infinite;
}
.wave {
  width: 5px;
  height: 20px;
  font-family: monospace;
  color: rgba(210, 21, 21, 0.673);
  background: linear-gradient(45deg, cyan, #fff);
  margin: 5px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}
.wave:nth-child(11) {
  animation-delay: 1s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loadcolors {
  0% {
    background-color: rgb(116, 239, 2);
  }
  25% {
    background-color: rgb(0, 72, 255);
  }
  50% {
    background-color: rgb(255, 4, 0);
  }
  75% {
    background-color: rgb(214, 228, 5);
  }
  100% {
    background-color: rgb(116, 239, 2);
  }
}
