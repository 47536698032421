.releaseSection{
    font-family: 'Play';
}

/* release header */
.releaseTitle{
    font-size:32px;
    display:inline;
    font-family:'Sarina';
    color:rgb(59, 24, 92);
    text-decoration:none;
    letter-spacing: -2px;
}
.releaseTitle:hover{
    color:rgb(55, 106, 194);
}
.releaseArtists{
    margin-left:2px;
    font-size:23px;
    display:inline;
    color:crimson;
    font-family:'Gloria Hallelujah';
    cursor:crosshair;
}
.releaseArtists:hover{
    color:darkorange;
}
.RYMSearchTrack{
    font-size:7px;
    display:inline;
    padding:5px;
}
.spotifySearchRelease{
    color:rgb(27, 180, 27);
    font-size:12px;
    display:inline;
}
.bandcampSearchRelease{
    color:rgb(23, 130, 218);
    margin-left:5px;
    font-size:12px;
    display:inline;
}
/* release data block */
.releaseDataContainer{
    display:flex;
}

.releaseData{
    margin:-5px 0px 0px 0px;
    background-color: rgb(54, 73, 143);
    font-size:10px;
    display:inline-block;
    padding:3px 7px 5px 9px;
    color:rgb(224, 224, 224);
    border-radius:3px;
}
.releaseGenres{
    font-size:14px;
    padding-bottom:3px;
    color:rgb(216, 236, 0);
}
.releaseDateCountry{
    padding:2px;
}
.releaseDate{
    background-color: aliceblue;
    display:inline;
    margin-right:5px;
    color:black;
}
.releaseCountry{
    display:inline;
    margin-right:10px;
}
.releaseLabel{
    background-color:rgb(207, 207, 207);
    display:inline;
    color:red;
    font-family: 'Gloria Hallelujah';
    padding:3px;
    margin-right:10px;
    border-radius:2px;
}
.releaseLabel:hover{
    cursor: pointer;
    background-color:rgb(255, 190, 190);
    transition:0.7s;
}
.releaseFormat{
    display:inline;
}

.addFavoriteRelease{
    color:red;
    font-size:30px;
    cursor: pointer;
    display:inline;
    margin:10px;
    transform: translate(-4px,-5px);
}
.addFavoriteRelease:hover{
    animation: heartAnimation 1s linear infinite;
}

.releaseNotes{
    margin:9px 0px 2px 0px;
    display:block;
    max-width: 40%;
    background-color: cornsilk;
    font-size:7px;
    transition:0.7s;
}
.releaseNotes:hover{
    font-size:19px;
    background-color:white;
    transition:2s;
}


/* video css */
.releaseIframe{
    height:40px;
    width:40px;
    font-size: 1px;
}
/* .releaseIframe:hover{
    height:200px;
    width:200px;
    transition:1s;
} */
/* we are now using thumbnails instead as a functional link to the player... more on this soon */
.releaseVideos{
    margin-top: 0px;
    padding:2px;
}
.videoOuterContainer{
    position:relative;
    display:inline;
}
.thumbnailContainer{
    position:relative;
    display:inline;
    margin: 0px 4px 0px 0px;
    
}
.videoThumbnail{
    margin:2px;
    display:inline;
    width:40px;
    height:40px;
    cursor:pointer;
    transition:0.4s;
    padding:2px;
    background-color: rgba(0, 255, 255, 0.182);
}
/* .videoThumbnail:hover{
    width:42px;
    height:42px;
    transition:0.4s;
} */
.thumbnail-title {
    font-size: 12px;
    display: block;
    position: absolute; /* This will overlay the title on the thumbnail */
    top: 50%; /* center vertically */
    left: 50%; /* center horizontally */
    transform: translate(-50%, -50%); /* necessary to perfectly center */
    /* we just transition the opacities to show them */
    background-color: rgba(136, 136, 136, 0.0);
    color: rgba(255, 255, 255, 0);
    transition:0.3s;
    transform:translate(10px,10px);
    padding: 1px;
    border-radius: 5px;
    cursor:pointer;
}
/* .thumbnailContainer:hover .thumbnail-title {
    background-color: rgba(136, 136, 136, 0.611);
    color: rgba(255, 255, 255, 1);
    transition:0.3s;
} */
.playThumbnail{
    mix-blend-mode:normal;
    position:absolute;
    font-size: 13px;
    top:50%;
    left:60%;
    z-index: 3;
    color:rgb(255, 0, 0);
    cursor:pointer;
}
.thumbnailContainer:hover  .playThumbnail{
    color:beige;
}
.addVideoToPlaylist{
    margin-right:3px;
    cursor:crosshair;
    display:absolute;
    top:0px;
    left:0px;
    font-size:14px;
    color:rgb(210, 106, 8);
    border-radius:20px;
    :hover{
        color:rgb(107, 116, 99);
    }   
}
.addAllToPlaylist{
    border:none;
    display:inline;
    height:19px;
    width:35px;
    font-size:12px;
    background:rgb(218, 16, 66);
    color:rgb(255, 255, 255);
    padding-top:3px;
}
.addAllToPlaylist:hover{
    background-color: rgb(167, 198, 198);
}

/* release images */
/* now set more in the component MagImage */
.releaseImagesContainer{
    position:relative;
    flex-wrap:wrap;
    display:flex;
    align-items: center;
}
.releaseImage{
    display:inline;
    margin-right:4px;
    transition: 1s;
}
.magImage{
    position:flex;
}
.releaseImage:hover{
    /* cursor: -webkit-zoom-in;
    cursor: zoom-in; */
}
/* lets add this::after
https://dev.to/anxiny/create-an-image-magnifier-with-react-3fd7
 */
/* .releaseImage img{
    cursor: -moz-zoom-in; 
    cursor: -webkit-zoom-in; 
    cursor: zoom-in;
} */

/* notes and tracklist */
.tracklistContainer{
    font-size:10px;
}
/* this is overrode, passed in by release calculation to the Track component */
.trackContainer{
    text-align:left;
}
.track{
    margin-top:2px;
    display:inline;
}
.trackPos{
    margin-top:2px;
    padding:2px;
    display:inline;
    border-radius:14px;
}
.trackArtist{
    display:inline;
}
.trackSearchesContainer{
    display:flex;
}
.youtubeSearchTrack{
    text-decoration: none;
    color:rgb(199, 29, 29);
    font-size:13px;
    margin-left:10px;
}
.spotifySearchTrack{
    text-decoration: none;
    color:rgb(1, 138, 31);
    font-size:14px;
    margin-left:5px;
    display:inline;
}
.appleSearchTrack{
    text-decoration: none;
    color:rgb(149, 148, 148);
    font-size:11px;
    margin-left:1px;
    padding-bottom:5px;
    display:inline;
    :hover{
        color:white;
    }
}


@keyframes heartAnimation {
    0%{
        color:rgb(255, 0, 0);
        font-size:30px;
    }
    25%{
        color:rgb(255, 106, 0);
    } 
    50%{
        color:rgb(187, 255, 0);
        font-size:34px;
    } 
    75%{
        color:rgb(255, 99, 247);
    }  
    100%{
        color:rgb(255, 0, 0);
    }  
}