.discogsRandomizerOuterContainer {
  margin: -15px 0px 0px 0px;
  padding: 3px 0px 0px 0px;
}

.discogsInputs {
  background-color: rgb(211, 220, 225);
  display: inline-block;
  margin: 10px 0px 2px 1px;
  padding: 0px 4px 0px 2px;
  font-family: "Tilt Neon";
  font-size: 10px;
  border: 0.1em solid rgb(181, 180, 180);
}
.discogsInputs input {
  border-radius: 5px;
}
.randomizerButton {
  background-color: rgb(157, 231, 123);
  color: rgb(24, 21, 21);
  border: 1px;
  display: inline;
  padding: 2px;
  margin: 4px;
  border-radius: 5px;
}
#randomRelease {
  background-color: rgb(210, 63, 14);
  color: aqua;
}
.randomizerButton:hover {
  background-color: rgb(195, 255, 255);
}

.randomSizeInput {
  margin: 5px;
  width: 60px;
}
.artistSearchForm {
  display: inline;
  margin: 4px;
}
.instructionsModalText {
  font-size: 12px;
  background-color: rgb(163, 157, 157);
  color: rgb(0, 0, 0);
}
.instructionsModal {
  height: 20px;
  font-size: 8px;
  border: 0px;
  background-color: rgb(0, 94, 148);
  padding: 1px;
}
.modalCloseButton {
  margin: 0px;
  height: 30px;
  background-color: yellow;
  color: blue;
  font-size: 14px;
  border: 0px;
}
.instructionsHeader,
.instructionsFooter {
  background-color: red;
  color: aqua;
  height: 46px;
}

/* artistname header */
.artistName {
  margin-right: 5px;
  font-size: 27px;
  display: inline;
  background-color: crimson;
  color: rgb(166, 207, 243);
  font-family: "Russo One";
  padding-right: 12px;
  padding-left: 5px;
  border-radius: 10px;
  text-decoration: none;
}
.artistName:hover {
  color: aquamarine;
}
.artistImage {
  display: inline;
  width: 40px;
  height: 40px;
  margin-right: 4px;
  border-radius: 5px;
}
.artistImage:hover {
  width: 100px;
  height: 100px;
}
.artistRealName {
  font-size: 4px;
  display: inline;
}

/* urls */
.urlsContainer {
  display: block;
  margin-top: -5px;
  margin-bottom: -5px;
}
.artistUrl {
  font-size: 3px;
  margin: 1px;
  cursor: pointer;
  color: rgb(214, 240, 119);
  text-decoration: none;
  letter-spacing: 0px;
  border: 1px solid rgb(196, 179, 179);
}
.artistUrl:hover {
  color: rgb(176, 45, 45);
  font-size: 10px;
}
#bandcampUrl {
  color: rgb(94, 214, 20);
  font-size: 7px;
  hover {
    font-size: 15px;
    color: black;
  }
}
#soundcloudUrl {
  color: rgb(218, 133, 7);
  font-size: 7px;
  hover {
    font-size: 15px;
    color: black;
  }
}

/* aliases */
.aliasesContainer,
.groupsContainer,
.membersContainer {
  text-align: left;
  display: block;
}
.aka,
.inGroups,
.members {
  font-size: 9px;
  display: inline-block;
}
.aka {
  background-color: rgb(248, 194, 194);
}
.inGroups {
  background-color: rgb(230, 230, 240);
}
.members {
  background-color: rgb(224, 224, 224);
}
.artistAliases,
.groupsList,
.membersList {
  font-size: 10px;
  display: inline-block;
  background-color: azure;
}
.alias,
.member,
.group {
  padding: 5px;
  display: inline;
  cursor: pointer;
}
.alias:hover,
.group:hover,
.member:hover {
  color: pink;
}

.artistSection {
  font-family: "Varela Round";
}

.catalogDisplay {
  color: rgb(3, 78, 64);
  margin: -5px 0 0 14px;
}
.totalReleasesLabel {
  display: inline-block;
}
#onlyMainLabel {
  padding-top: 2px;
  font-size: 7px;
}
.css-checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.css-checkbox + label {
  vertical-align: bottom;
  position: relative;
  font-size: 7px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 20px;
  color: rgb(0, 0, 0);
}
.css-checkbox + label::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  margin-left: 5px;
  width: 10px;
  height: 10px;
  background-color: rgb(57, 92, 206);
  border-width: 0px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-radius: 2px;
  box-shadow: none;
}
.css-checkbox:checked + label::after {
  content: " ";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZGUxZmNiIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: 9px 9px;
  background-position: center center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  left: 0px;
  top: 0px;
  text-align: center;
  background-color: transparent;
  font-size: 10px;
  height: 20px;
  width: 20px;
}

.artistProfile {
  display: block;
  font-size: 9px;
  margin-top: 1px;
  margin-bottom: 0px;
}
.artistProfile:hover {
  font-size: 12px;
}

.spaceAtBottomOfDiscogs {
  min-height: 20px;
}
