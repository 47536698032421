.releaseListAnchor{

}
.releasesDisplayContainer{
    display:inline-block;
    vertical-align: top;
    cursor:crosshair;
    max-width:800px;
    align-items: right;
    text-align: left;
    padding:10px;
}
.releaseList{
    margin:0px 0px 0px 5px;
    font-family:'Concert One';
    font-size:9px;
    display:inline;
    background-color: rgb(247, 247, 247);
}
.showHideReleaseList{
    font-size:7px;
    transition:0.5s;
    padding:3x;
    cursor:crosshair;
    display:inline;
    background-color: rgb(255, 255, 255);
}
.showHideReleaseList:hover{
    color:red;
    font-size:10px;
    transition:.4s;
}
.releaseInList{
    font-size:12px;
    background-color: rgb(158, 155, 155);
    color:rgb(11, 81, 165);
    margin-left:20px;
    transition:1s;
}
.releaseInList:hover{
    color:pink;
    background-color:rgb(94, 94, 94);
    transition:0.7s;
}
.releaseListYear{
    background-color:rgb(63, 224, 165);
    padding:2px;
    color:black;
    transition:0.7s;
}
.releaseListYear:hover{
    font-size:40px;
    transition:0.7s;
}
.releaseRole{
    display:inline;
}
#mainRelease{
    background-color:rgb(221, 83, 83);
    color:rgb(238, 238, 238);
    padding:3px;
}
#otherRelease{
    background-color:pink;
    padding:3px;
}
.releaseThumb{
    width:40px;
    height:40px;
    margin:2px 4px 4px;
}

.releaseCatno{
    color:rgb(116, 7, 7);
    display:inline;
    padding:5px;
    font-size:8px;
}