.ticker {
    width: 200px; /* Adjust width as needed */
    overflow: hidden; /* Hides the overflowing text */
    white-space: nowrap; /* Prevents the text from breaking into multiple lines */
    border: 5px solid red; /* Optional: Just to visualize the ticker container */
    font-family:Arial, Helvetica, sans-serif;
    background-color: aqua;
    animation: ticker-animate 10s linear infinite;
}

.ticker-text {
    display: inline-block;
    padding-left: 100%; /* Start the animation off the right side of the container */
    animation: scroll-left 120s linear infinite; /* Adjust 10s to make the scroll faster or slower */
}


@keyframes ticker-animate {
    from {
        background-color: aqua;
    }
    to {
        background-color:firebrick;
    }
}

@keyframes scroll-left {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}