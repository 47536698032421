.labelSection{
    font-family:'Tilt Neon';
    /* padding-bottom:12px; */
}


.labelImage{
    width:35px;
    height:35px;
    margin-right:10px;
    vertical-align: bottom;
}
.labelImage:hover{
    width:150px;
    height:150px;
}
.labelName{
    color:rgb(52, 14, 14);
    font-size:30px;
    letter-spacing:9px;
    display:inline;
    vertical-align: bottom;
    text-decoration: none;
    line-height: 1; /* Resetting line-height */
    margin: 0;      /* Removing margin */
    padding: 0;     /* Removing padding */
}

.labelId{
    font-size:8px;
    display:inline;
    padding:10px;
    vertical-align: bottom;
}
.totalReleasesLabel{
    font-size:8px;
}

.labelProfile{
    background-color:white;
    display:inline-block;
    font-size:11.5px;
}
.labelContact{
    font-size:6px;
    padding-bottom:0px;
    padding-bottom:0px;
    display:inline-block;
    vertical-align: top;
    background-color: darkgray;
}

/* .urlsContainer{
    vertical-align: top;

} */
.urlSpan{
    vertical-align:top;
}
.labelUrl{
    font-size:7px;
    padding:0px;
    margin:0px;
    vertical-align: top;
}