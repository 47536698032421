.favoritesHeadline{
    font-size:13px;
    margin:3px;
}
.favorites{
    z-index:100;
    margin: 5px 8px 0px 0px;
    text-align: right;
    position:fixed;
    display:inline;
    top:0px;
    right:0px;
    background-color:rgb(211, 211, 211);
    font-size:10px;
}
.favorite{

}
.favoriteTrack{

    :hover{
        color:red;
    }
}

.favoriteTrackThumbnail{
    display:inline;
    height:30px;
    width:30px;
}