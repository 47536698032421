.playlistOuterContainer{
    display:flex;
}

//variables for width and pos of button
$queueWidth: 240px;
$buttonX: $queueWidth/2.5;

// item1
.playlistContainer{
    position:relative;
}
//item2
.removeAllItemsFromQueue{
    mix-blend-mode: exclusion;
    transform:translateX($buttonX);
    margin:2px;
    padding:1px;
    height:15px;
    font-size:5px;
    width:45px;
    color:rgb(0, 0, 0);
    background-color: rgb(174, 138, 47);
    z-index:999;
    border:none;
    border-radius:5px;
    :hover{
        color:yellow;
    }
}

.playlist{
    background-color: rgba(95, 165, 49, 0.206);
    position:absolute;
    bottom:0px;
    max-height: 250px;
    overflow-y: scroll;
    scrollbar-color: red orange;
    scrollbar-width: none;
    padding:3px 20px 5px 2px;
    border-radius: 19px;
    width:$queueWidth;
    animation:playlistBackground 100s linear infinite;
}
.playlist::-webkit-scrollbar {
    width: 0.2em;
    transform: translateX(-20px);
}
.playlist::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(217, 113, 113, 0.584);
}
.playlist::-webkit-scrollbar-thumb {
  background-color: rgb(41, 168, 162);
  outline: 1px solid rgb(31, 163, 143);
}


.playlistRender{
    
}
.playlistRender > li{
    // display:flex;
    // flex-flow:row nowrap;
    // align-items:center;
}
.playlistItemContainer{
    font-size:7px;
    padding:0px;
    margin:0px;
    margin-bottom:-3px;
    color:rgb(231, 123, 243);
    list-style-type: none;
}
.playListItem:hover{
    color:pink;
    cursor:pointer;
}
.playItem{
}
.playlistImage{
    height: 20px;
    width: 20px;
    margin-right:3px;
}
.removeItemFromPlaylist{
    cursor:pointer;
    margin-left:4px;
    font-size:12px;
    color:rgb(40, 169, 81);
    background-color: rgba(0, 128, 0, 0);
    display:inline;
    :hover{
        color:red;
        background-color:rgb(28, 211, 21);
        cursor:pointer;
    }
}

@keyframes playlistBackground {
    0%{
        background-color:rgba(95, 165, 49, 0.206);
    }
    25%{
        background-color:rgba(146, 49, 165, 0.206);
    }
    50%{
        background-color:rgba(127, 68, 29, 0.327);
    }
    75%{
        background-color:rgba(94, 52, 218, 0.369);
    }
    0%{
        background-color:rgba(95, 165, 49, 0.206);
    }
}