.coolSketch{
  position:relative;
}
.warpSketch{
  position:absolute;
  top:10%;
  left:10%;
  mix-blend-mode: difference;
  animation: rotator2 20s linear infinite;
}
.artPageLink{
    position: absolute;
    font-size: 50px;
    top:50%;
    left:40%;
    background-color:green;
    mix-blend-mode: difference;
    animation: rotator 10s linear infinite;
}
.artPageLink:hover{
    color:red;
    cursor:crosshair;
}


@keyframes rotator {
    from {
      transform: scale3d(.7, .7, .3)rotate(0deg);
      background-color:blueviolet;
    }
    50% {
      transform: scale3d(1, .5, 1)rotate(30deg);
    }
    77% {
      transform: scale3d(2, .1, 2)rotate(555deg);
    }
    to {
      transform: scale3d(.7,.7,.3)rotate(720deg);
      background-color:orange;
    }
  }
  @keyframes rotator2 {
    from {
      transform: scale3d(.7, .7, .3)rotate(0deg);
      background-color:rgb(154, 146, 160);
    }
    43% {
      transform: scale3d(1, .5, 1)rotate(30deg);
      background-color:rgb(55, 55, 55);
    }
    77% {
      transform: scale3d(2, .9, 1.3)rotate(397deg);
    }
    to {
      transform: scale3d(.7,.7,.3)rotate(720deg);
      background-color:rgb(39, 238, 43);
    }
  }