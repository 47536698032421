body {
  //background-color: rgb(147, 206, 221);
  margin-left: 19px;
  margin-top: 10px;
}
* {
  box-sizing: border-box;
}

.fullPageContainer {
  background-color: aqua;
}
.processingWrapper {
  display: inline-block;
}
.siteTitleSketch {
  display: inline-block;
}
.appNavContainer {
}
.headerNavLink {
  display: inline-block;
  vertical-align: top;
  padding-left: 0px;
  font-family: "Nabla";
  font-size: 20px;
  text-decoration: none;
  color: pink;
}
#diskographies {
  display: inline-block;
  font-family: "Foldit";
  letter-spacing: 3px;
  background-color: rgb(255, 255, 255);
  text-decoration: none;
  color: rgb(0, 0, 0);
  transition: 5s;
}
#diskographies:hover {
  color: rgb(0, 255, 42);
  background-color: rgb(53, 220, 20);
  letter-spacing: 6px;
  /* font-family:'Libre Barcode 39 Extended Text'; */
  transition: 3s;
}
#art {
  color: aqua;
  background-color: rgb(12, 111, 232);
  letter-spacing: 12px;
  font-family: "Nabla";
  font-weight: 800;
  font-variation-settings: "wght" 800;
  transition: 10s;
}
#art:hover {
  color: deeppink;
  background-color: rgb(0, 0, 0);
  font-stretch: condensed;
  letter-spacing: 0px;
  transition: 5s;
}
#login {
  background-color: black;
  padding-left: 3px;
}
#login:hover {
  background-color: pink;
}
#logout {
  background-color: pink;
  cursor: pointer;
  padding-left: 3px;
}
#logout:hover {
  background-color: red;
}
#settings {
  font-family: "Sarina";
  cursor: pointer;
  padding-left: 10px;
}
#settings:hover {
  color: chartreuse;
}

.crazyDiv:hover {
  background-color: yellow;
  border-radius: 5px;
  width: 2px;
  height: 5px;
  animation: rotator 1s linear infinite;
}

@keyframes rotator {
  from {
    transform: scale3d(0.7, 0.7, 0.3) rotate(0deg);
  }
  50% {
    transform: scale3d(1, 0.5, 1) rotate(30deg);
  }
  77% {
    transform: scale3d(2, 0.1, 2) rotate(555deg);
  }
  to {
    transform: scale3d(0.7, 0.7, 0.3) rotate(720deg);
  }
}
